.logo {
  width: 65px;
}
.navbar {
  background-color: #13161d;

  transition: box-shadow 0.3s ease-in-out;
  /* height: 100px; */
}
.nav-things {
  margin-left: 420px;
}

.navbar.scrolled {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  margin-right: auto;
}

.navbar-nav {
  margin: 0 auto;
}

.nav-link {
  padding: 0.5rem 1rem;
}
.nav-link {
  color: white;
  font-weight: 500;
}
.nav-link:hover {
  text-decoration: underline wavy #0c976d 3px;
  /* color: white !important; */

  /* border: 1px black solid; */
  /* border-radius: 7px; */
  /* height: 40px; */
  /* width: 70px; */
  /* background-color: white; */
}
.about:hover,
.portfolio:hover,
.services:hover,
.blog:hover {
  color: white;
}
.servv{
  color: white;
  cursor: pointer;
}

.in-touch {
  position: relative;
  margin-left: 22vw;
  width: 140px;
  height: 50px;
  /* border: 1px white solid; */
  border: none;
  /* background-color: transparent; */
  overflow: hidden;
  background-color: #39d3a5;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* backdrop-filter: blur(50px) !important; */
  border-radius: 32px;
  font-weight: 500;
  /* z-index: 1; */
  color: white;
  transition: background-color 2s;
  transition-timing-function: ease-out;
}

/* .in-touch::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(97, 159, 252);
  filter: blur(8px);
  z-index: -1;
} */
.in-touch:hover {
  border: 1px white solid;
  background-color: #5ae6bc;
}

@media (max-width: 767.98px) {
  .navbar-collapse {
    justify-content: space-between;
  }

  .nav-link {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .in-touch {
    display: none;
  }
  .nav-things {
    margin-left: 0px;
  }
}

a {
  text-decoration: none;
}
a:hover {
  color: white;
}

@media screen and (min-width: 100px) and (max-width: 299px) {
  .logo {
    width: 40px;
  }
}
@media screen and (min-width: 300px) and (max-width: 370px) {
  .logo {
    width: 50px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  
  .nav-things {
    margin-left: 23vw;
  }
}
