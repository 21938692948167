.foots{
    background-color: #13161d;
    color: white;
}
.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  a{
    text-decoration: none !important;
  }
  .footb{
    font-weight: 700;
  }
  .footy{
    color: white;
    /* margin-top: 15px; */
    /* font-size: 15px; */
  }
  /* .footy:hover{
    background-color: rgb(3, 37, 87) !important;
  } */
  .foott{
    margin-top: 15px;
    margin-bottom: 15px;
  }