/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #095c47;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #09b98e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #012018;
}
/* scrollbar */

/* slider */
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
}

.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  /* height: 300px; */
  position: relative;
  margin: auto;
}
.head-1 {
  background-color: #13161d;
  /* margin-top: 46px; */
}
.headoo {
  margin: auto;
}
.head-w1,
.head-w2 {
  color: white;
}
.head-w1 {
  color: #0c976d;
  font-weight: bold;
  font-size: 70px;
  /* animate__fadeInUp */

  animation: fadeInUp;
  animation-duration: 2s;
}
.head-w2 {
  font-size: 18px;
  text-align: justify;
}

.logos {
  width: 100%;
  /* height: 200px; */
}
.what-we {
  text-align: center;
}
.what-we1 {
  font-size: 30px;
  font-weight: bold;
}
.what-we2 {
  font-size: 18px;
  font-weight: 600;
}

.we-do-a {
  background-color: #0c976d;
  border-radius: 5px;
  color: white;
  border: 1px black solid;
  text-align: center;
  margin-right: 10px;
  width: 286px;
  height: 300px;
}
.we-do-a-l {
  background-color: #0c976d;
  border-radius: 5px;
  color: white;
  border: 1px black solid;
  text-align: center;
  margin-right: 0px;
  width: 286px;
}

.vis-w2 {
  text-align: justify;
}

.list-p {
  list-style-type: none;
}
.code-img {
  width: 15%;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot-span {
  text-align: center;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.clients {
  background-color: #0c976d;

  height: 170px;
  margin-bottom: 70px;
}
.cl-a {
  padding: 70px 0;
}
.trusted {
  color: white;
  font-size: 30px;
  font-weight: bold;
}
.trust-img {
  width: 85%;
}

.cl-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hay-img {
  width: 80px;
  height: 80px;
  margin-top: -9px;
}

#services {
  /* margin: 5% 0; */
  margin-top: 40px;
}
.what-wee {
  padding: 300px 0;
}

.fourboxes-container {
  border: 1px solid black;
  color: white;
  padding: 25px;
  height: 300px;
  width: 275px;
  /* margin-left: 20px; */
  border-radius: 7px;
  margin-bottom: 20px;
  background-color: #0c976d;
  /* border-color: #000; */
}

.fourboxes2 {
  color: white;

  font-size: 50px;
  font-weight: 700;
}

.fourboxes3 {
  color: #828282;

  font-size: 19px;
  font-weight: 500;
}

.fourboxes4 {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.fourboxes5 {
  font-size: 15px;
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: normal;
}

.fourboxes-container:hover {
  animation: pulse;
  animation-duration: 3s;
  background-color: #075e44;
  color: #fff;
}

.fourboxes-img {
  width: 50px;
  height: 50px;
  margin-bottom: 30px;
}
.typing-a {
  padding: 198px 0;
}

.typing-b1 {
  color: white;

  font-size: 50px;
  font-weight: 700;
}
.typing-b2 {
  color: #828282;
}
.arrow {
  font-size: 30px;
}
.why-we,
.why-we2 {
  text-align: center;
}
.why-we {
  font-weight: 500;
  font-size: 25px;
  color: #0c976d;
}
.why-we2 {
  font-weight: 700;
  font-size: 47px;
}
.why-we-a {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.why-we-a1 {
  background-color: #202531;
  height: 260px;
  width: 320px;
  padding: 25px;
}
.why-we-a1:hover {
  box-shadow: rgba(12, 151, 109, 0.3) 0px 4px 16px,
    rgba(12, 151, 109, 0.3) 0px 8px 24px, rgba(12, 151, 109, 0.3) 0px 16px 56px;
}
.why-we-img {
  width: 20%;
  margin-bottom: 15px;
  margin-right: 10px;
}
.why-we-oro0 {
  text-align: right;
  font-weight: 600;
}
.why-we-oro {
  text-align: left;
  color: #828282;
}

.port-a {
  margin: 0 auto;
  width: 90%;
  border: 2px #0c976d solid;
  border-radius: 10px;
  height: 270px;
}
.port-a1 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-evenly; */
  align-items: center;
}
/* .port-img {
  width: 90%;
} */

.travel {
  margin: 0 20px;
  cursor: pointer;
  width: 40%;
  height: 70%;
  background-image: url("../assets/images/travel.png");
  background-size: cover;
  transition: background-image 0.7s ease;
}
.travel:hover {
  cursor: pointer;
  background-image: url("../assets/images/travel2.png");
  opacity: 0.4;
}
.simmie {
  cursor: pointer;
  width: 40%;
  height: 70%;
  background-image: url("../assets/images/simmie.png");
  background-size: cover;
  transition: background-image 0.7s ease;
}

.simmie:hover {
  cursor: pointer;
  background-image: url("../assets/images/simmie2.png");
  opacity: 0.4;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}
/* slider */

.vis {
  background-color: #976d0c;
  color: white;
  text-align: center;
}
.mis {
  background-color: #280c97;
  color: white;
  text-align: center;
}
.rep {
  background-color: #970c36;
  color: white;
  text-align: center;
}
.his {
  background-color: #7b970c;
  color: white;
  text-align: center;
}

.vis-w {
  margin-top: 22px;
  font-size: 25px;
  font-weight: 600;
}

.body-3 {
  color: white;
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
  font-size: 15px;
}
@media screen and (min-width: 100px) and (max-width: 299px) {
  .vis-w2 {
    font-size: 12px;
    text-align: justify;
  }
  .fourboxes2 {
    font-size: 30px;
    font-weight: 700;
  }
  .fourboxes-container {
    /* text-align: center; */
    width: 240px;
  }
  .why-we-a {
    display: flex;
    flex-direction: column;
  }
  .cl-b {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .unboxed-img {
    width: 30%;
  }
  .trust-img {
    width: 30%;
    margin-top: 20px;
  }
  .hay-img {
    width: 80px;
    height: 80px;
    margin-top: 20px;
  }
  .typing-b1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
  }
  .typing-b2 {
    font-size: 15px;
    text-align: justify;
    padding-left: 15px;
    padding-right: 15px;
  }
  .why-we-a1 {
    margin-bottom: 20px;
  }
  .why-we-a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .port-a {
    margin: 0 auto;
    width: 90%;
    border: 2px #0c976d solid;
    border-radius: 10px;
    height: 700px;
  }
  .port-a1 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* justify-content: space-evenly; */
    align-items: center;
  }
  .travel,
  .simmie {
    margin: 20px 0px;
    width: 95%;
    height: 100%;
  }
}
@media screen and (min-width: 300px) and (max-width: 380px) {
  .fourboxes-container {
    /* text-align: center; */
    width: 300px !important;
  }
  .why-we-a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .why-we {
    font-size: 22px;
    color: #0c976d;
  }
  .why-we2 {
    font-weight: 700;
    font-size: 33px;
  }
  .why-we-a1 {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 301px) and (max-width: 500px) {
  .head-w1 {
    padding-top: 40px;
  }
  .fourboxes-container {
    /* text-align: center; */
    width: 360px;
  }
  .why-we-a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .why-we-a1 {
    margin-bottom: 20px;
  }
  .why-we {
    font-size: 22px;
    color: #0c976d;
  }
  .why-we2 {
    font-weight: 700;
    font-size: 33px;
  }
  .cl-b {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .unboxed-img {
    width: 30%;
  }
  .trust-img {
    width: 30%;
    margin-top: 20px;
  }
  .hay-img {
    width: 80px;
    height: 80px;
    margin-top: 20px;
  }
  .typing-b1 {
    text-align: center;
    font-size: 33px;
    font-weight: 700;
  }
  .typing-b2 {
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: justify;
  }
  .port-a {
    margin: 0 auto;
    width: 90%;
    border: 2px #0c976d solid;
    border-radius: 10px;
    height: 700px;
  }
  .port-a1 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* justify-content: space-evenly; */
    align-items: center;
  }
  .travel,
  .simmie {
    margin: 20px 0px;
    width: 95%;
    height: 100%;
  }
}

@media screen and (max-width: 850px) {
  .head-w1 {
    /* margin-top: 70px; */
    font-size: 40px;
    text-align: center;
  }

  .what-wee {
    text-align: center;
    padding: 0;
  }
  .areas-things {
    text-align: center;
  }

  .body-3 {
    text-align: center;
    text-align: justify;
    margin-top: 50px;
  }
  .typing-a {
    padding: 0;
  }

  .clients {
    height: 350px;
    margin-bottom: 70px;
    text-align: center;
  }

  .cl-a {
    padding: 35px 0;
  }

  .trusted {
    font-size: 40px;
    font-weight: bold;
  }

  .lala {
    text-align: center;
  }
}
@media screen and (min-width: 851px) and (max-width: 1200px) {
  .fourboxes-container {
    width: 230px;
  }
}
/* @media screen and (min-width: 870px) and (max-width: 1100px) {
  .fourboxes-container {
    width: 400px !important;
    margin-left: 100px !important;
  }
} */
@media screen and (min-width: 501px) and (max-width: 850px) {
  .fourboxes-container {
    width: 170px;
    height: 310px;
  }
  .fourboxes4 {
    font-size: 15px;
  }
  .areas-things {
    text-align: left;
  }
  .clients {
    height: 170px;
  }
  .what-wee {
    padding: 300px 0;
  }
}
